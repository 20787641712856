.reservationReady{
    /* max-height: 10%; */
    margin-top: 1rem;
    display: grid; 
    grid-template-columns: repeat(2, 1fr);

}
/* .main{
    width: 300%;
}

.details{
    margin-left: 200%;
} */

.reservationMotor_detailReservation{
    margin-top: 1rem;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);*/
} 