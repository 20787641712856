@media (max-width: 767px) {
  .main-card{
    border: solid 1px black; 
    max-height: 80%;
    max-width: 90%;
    margin-left: 5%;
    padding: 1em; 
    margin-top: 1rem;
    background-color: rgb(216, 221, 226);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }
}

/** dispositivos medianos (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
    .main-card{
        border: solid 1px black; 
        max-height: 80%;
        max-width: 70%;
        margin-left: 15%;
        padding: 1em; 
        margin-top: 1rem;
       
      }
      .carrousel{
        width: 80%;
      }
  
}

/** dispositivos grandes (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
  
}

/** dispositivos extra grandes (monitores grandes, tvs) */
@media (min-width: 1200px) {
    .main-card{
        border: solid 1px black;
         padding: 1em;
         max-height : 80%;
         max-width: 50%;
         margin-left: 25%; 
         border-radius: 15px;
         margin-top: 2rem;
     }
}