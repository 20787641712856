.form{
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: white;
    width: 120%;
    margin-left: 35%; */
    border: solid 2px black; 
    margin: 2em;
    background-color: beige;    
}

.input{
    width: 90%;
    margin: 1rem; 
    border: solid 0.1px black;
}

.contact{
    background-color: white;
}

.text-area{
    width: 90%;
}

.formikErrors{
    color: red
}

@media (min-width: 1200px){
    .form{
        max-width: 60%;
        margin-left: 20%;
    }
    .boton{
        margin-top: -5%;
        
    }

}

