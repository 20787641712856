@media(max-width: 599px){
    .logo{
        width: 50%;
    }
}

@media screen and (min-width: 600px) and (max-width:956px){
    .logo{
        width: 30%;
    }
}