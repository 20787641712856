@media(max-width: 767px) {
    .carrousel-img{
       height: 90vh;
       width: 90%;
    }
 
}

/** dispositivos medianos (tablets) */
@media (min-width: 768px) and (max-width: 991px) {
    .carrousel-img{
       height: 90vh;
       width: 90%;
    }
}

/** dispositivos grandes (desktops) */
@media (min-width: 992px) and (max-width: 1199px) {
    .carrousel-img{
        height: 90vh;
        width: 90%;
    }
}

/** dispositivos extra grandes (monitores grandes, tvs) */
@media (min-width: 1200px) {
    .carrousel-img{
        height: 70vh;
        width: 80%;
    }  
}